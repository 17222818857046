const SalesAgentTooltips = {
  activePapp: "Switch on for this agent to receive leads and be active on fertighaus",
  msActivePapp: "Switch on for this agent to receive leads and be active on MS sites",
	imActivePapp: "Switch on for this agent to receive leads and be active on IM sites",
  externalId: "",
  salesOffice: "Select a sales office the agent belongs to",
  firstName: "Sales agent's given name",
  lastName: "Sales agent's surname",
  gender: "Sales agent's gender used for personalized mailings",
  email: "Sales agent's email address",
  phoneCountryPrefix: "Sales agent's phone number country prefix",
  phone: "Sales agent's phone number",
  addressLine1: "Street & house-number of the sales agent's address",
  postalCode: "Postal code of the sales agent's address",
  city: "City of the sales agent's address",
  country: "Country of the sales agent's address",
  position: "Sales agent's position in the company",
  mandatoryMember: "If activated, the sales agent will be used as main counterpart of respective sales office and will receive all leads in case that delivery regions are only specified on sales office and not on sales agent level",
  avatar: "Image used to display sales agents on fertighaus",
  handoverRegions: "ZIP codes in which sales agent offers its services",
  displayRegions: "ZIP codes for which sales agent is shown as an option on fertighaus",
	imHandoverRegions: "ZIP codes in which agent offers its services",
	imDisplayRegions: "ZIP codes for which agent is shown as an option on the IM sites",
}

export default SalesAgentTooltips;